import React from "react";
import LoginPage from "../auth/Login.react";
import ForgotPassword from "../auth/forgot-password.react";
import ResetPassword from "../auth/reset-password.react";
import SuperAdminDashboard from "../admin/SuperAdminDashboard-react";
import AdminDashboard from "../admin/AdminDashboard-react";
import Chat from "../../src/admin/chat/Chat";
import AllChat from "../../src/admin/chat/AllChat";
// client
import ClientList from "../admin/clients/Clients-list-react";
import ClientCreate from "../admin/clients/Clients-create-react";
import ClientEdit from "../admin/clients/Clients-edit-react";
import ChangePassword from "../admin/settings/change-password.react";
import SuperAdminClientChangePassword from "../admin/settings/change-agent-password.react"
import Settings from "../admin/settings/settings.react";
import EndSettings from "../admin/settings/end-settings.react";
import GoogleSettings from "../admin/settings/google-settings.react";
import UpdateData from "../admin/settings/update-data.react";

import CategoryCreate from "../admin/campaigntabs/category/Category-create-react";
import CategoryList from "../admin/campaigntabs/category/Category-list-react";
import CategoryEdit from "../admin/campaigntabs/category/Category-edit-react";

import CampaignCreate from "../admin/campaigntabs/campaign/Campaign-create-react";
import CampaignList from "../admin/campaigntabs/campaign/Campaign-list-react";
import CampaignEdit from "../admin/campaigntabs/campaign/Campaign-edit-react";

import ClientProfile from "../clients/settings/profile.react";
import DashboardClient from "../clients/Dashboard-client-react";
import ClientChangePassword from "../clients/settings/change-password.react";
import TemplateCreate from "../admin/templates/template-create-react";
import ButtonCreate from "../admin/buttons/button-create-react";
import ShareData from "../admin/Dashboard-client-react";
import Analytic from "../admin/analytics/Analytic";
import LeadDashboard from "../admin/leadDashboard";

import { Redirect } from "react-router";
import Doctor from "../admin/Doctor";

import { Error404 } from "../pages";
import leadDashboardClient from "../admin/leadDashboardClient";

const RedirectToCampaignHomeTab = () => {
  return <Redirect to={"/admin/campaigntabs/category/1"} />;
};

const routes = [
  {
    path: "/",
    exact: true,
    auth: true,
    component: SuperAdminDashboard,
  },
  {
    path: "/forgot-password",
    exact: true,
    auth: false,
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    exact: true,
    auth: false,
    component: ResetPassword,
  },
  {
    path: "/login",
    exact: true,
    auth: false,
    component: LoginPage,
  },
  {
    path: "/admin/dashboard",
    exact: true,
    auth: true,
    component: SuperAdminDashboard,
  },
  {
    path: "/admin/clients/:page",
    exact: true,
    auth: true,
    component: ClientList,
  },
  {
    path: "/admin/dashboard/:id",
    exact: true,
    auth: true,
    component: SuperAdminDashboard,
  },
  {
    path: "/admin/:clientId/dashboard/:page",
    exact: true,
    auth: true,
    component: ShareData,
  },
  {
    path: "/admin/chat/:page",
    exact: true,
    auth: true,
    component: Chat,
  },
  {
    path: "/admin/all-chat/:page",
    exact: true,
    auth: true,
    component: AllChat,
  },
  {
    path: "/admin/all-chat/:page/:userId",
    exact: true,
    auth: true,
    component: AllChat,
  },
  {
    path: "/admin/chat/:page/:userId",
    exact: true,
    auth: true,
    component: Chat,
  },
  {
    path: "/admin/dashboard/:id/:page",
    exact: true,
    auth: true,
    component: SuperAdminDashboard,
  },
  {
    path: "/admin/dashboard/:id/:page/:userId",
    exact: true,
    auth: true,
    component: SuperAdminDashboard,
  },
  {
    path: "/admin/client/add",
    exact: true,
    auth: true,
    component: ClientCreate,
  },
  {
    path: "/admin/client/edit/:id",
    exact: true,
    auth: true,
    component: ClientEdit,
  },
  {
    path: "/admin/change-password",
    exact: true,
    auth: true,
    component: ChangePassword,
  },
  {
    path: "/adminAgent/change-password/:id",
    exact: true,
    auth: true,
    component: SuperAdminClientChangePassword,
  },
  {
    path: "/admin/settings",
    exact: true,
    auth: true,
    component: Settings,
  },
  {
    path: "/admin/google-settings",
    exact: true,
    auth: true,
    component: GoogleSettings,
  },
  {
    path: "/admin/update-data",
    exact: true,
    auth: true,
    component: UpdateData,
  },
  {
    path: "/admin/end-settings",
    exact: true,
    auth: true,
    component: EndSettings,
  },
  /* {
    path: "/agent/dashboard/:page",
    exact: true,
    auth: true,
    component: AdminDashboard,
  },
  {
    path: "/agent/dashboard/:page/:userId",
    exact: true,
    auth: true,
    component: AdminDashboard,
  }, */
  {
    path: "/agent/change-password",
    exact: true,
    auth: true,
    component: ClientChangePassword,
  },
  {
    path: "/agent/button",
    exact: true,
    auth: true,
    component: ButtonCreate,
  },
  {
    path: "/client/:clientId/change-password",
    exact: true,
    auth: true,
    component: ClientChangePassword,
  },
  {
    path: "/client/:clientId/profile",
    exact: true,
    auth: true,
    component: ClientProfile,
  },
  {
    path: "/admin/templates",
    exact: true,
    auth: true,
    component: TemplateCreate,
  },
  {
    path: "/admin/analytics",
    exact: true,
    auth: true,
    component: Analytic,
  },
  {
    path: "/admin/lead-dashboard/:page",
    exact: true,
    auth: true,
    component: LeadDashboard,
  },
  {
    path: "/agent/lead-dashboard/:page",
    exact: true,
    auth: true,
    component: leadDashboardClient,
  },
  {
    path: "/admin/campaigntabs",
    exact: true,
    auth: true,
    component: RedirectToCampaignHomeTab,
  },
  {
    path: "/admin/campaigntabs/category/add",
    exact: true,
    auth: true,
    component: CategoryCreate,
  },
  {
    path: "/admin/campaigntabs/category/:page",
    exact: true,
    auth: true,
    component: CategoryList,
  },
  {
    path: "/admin/campaigntabs/category/edit/:id",
    exact: true,
    auth: true,
    component: CategoryEdit,
  },
  {
    path: "/admin/campaigntabs/campaign/add",
    exact: true,
    auth: true,
    component: CampaignCreate,
  },
  {
    path: "/admin/campaigntabs/campaign/:page",
    exact: true,
    auth: true,
    component: CampaignList,
  },
  {
    path: "/admin/campaigntabs/campaign/edit/:id",
    exact: true,
    auth: true,
    component: CampaignEdit,
  },

  { path: "/admin/doctor/:page", exact: true, auth: true, component: Doctor },

  {
    path: "*",
    auth: false,
    component: LoginPage,
  },
];

export default routes;
