// @flow

import React from "react";
import {
  Grid,
  Card,
  Table,
  Page,
  colors,
  Tooltip,
  Tag,
  Form, Button
} from "tabler-react";
import Select from 'react-select';
import Moment from 'moment';
import Slider from "react-slick";

import "tabler-react/dist/Tabler.css";
import C3Chart from "react-c3js";
import { Link, withRouter, browserHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

import SiteWrapperAdmin from "./SiteWrapper.admin.react";
import authActions from "../redux/auth/action";
import clientService from "../services/client";
import ReadmorePopup from './Readmore';
import InfoPopup from './InfoPopup';
import socketIOClient from "socket.io-client";
import "./slick/slick.css";
import "./slick/slick-theme.css";
import { changeLanguage } from '../../src/language/index'
import botService from "../services/bot";
import user from "../services/user";

// var ENDPOINT = "https://waagentportal.rpsapi.in";
var ENDPOINT = process.env.REACT_APP_baseUrl;
var socket;
var audio;



class AdminDashboard extends React.Component {

  constructor(props) {
    super(props)
    this.chatDiv = React.createRef()
    this.state = {
      messageText: '',
      currentPage: this.props.match.params.page,
      nextPage: false,
      totalRecords: 0,
      chatUsers: [],
      buttons: [],
      messages: [],
      selectChatUser: null,
      selectedFile: '',
      formDisabled: true,
      files: [],
      openPopup: false,
      openInfoPopup: false,
      page: 1,
      engageNumber: 0,
      search: '',
      user: this.props.user
    }
    audio = new Audio('https://waportal.rpsapi.in/notification_tune.mp3')
    this.handleChange = this.handleChange.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.closeInfoPopup = this.closeInfoPopup.bind(this);
    // this.changeLanguage = this.changeLanguage.bind(this);

  }

  closePopup(data) {
    this.setState({
      openPopup: {
        open: false,
        data: false
      }
    })
  }

  closeInfoPopup(data) {

    this.setState({
      openInfoPopup: {
        open: false,
        data: false
      }
    })
  }

  openInfoPopup(e, data) {

    let arr = data.entity
    const uniqueKeys = new Set();
    arr = arr.filter(obj => {  // temp fix to remove duplicates
      if (!uniqueKeys.has(obj['entity'])) {
        uniqueKeys.add(obj['entity']);
        return true;
      }
      return false;
    });

    arr = arr.map(el => {
      let obj = { ...el }
      if (el.entity == "step_1") {
        obj.entity = "Rating"
      }
      if (el.entity == "step_2") {

        obj.entity = "Department"
      }
      if (el.entity == "step_3") {

        obj.entity = "Suggestion"
      }
      return obj
    })


    arr = arr.filter(el=>el.entity != "lab_id")

    this.setState({
      openInfoPopup: {
        open: true,
        data: arr,
      }
    })
  }


  openPopup(e, data) {
    this.setState({
      openPopup: {
        open: true,
        data: data,
      }
    })
  }

  handleChange = (e, field) => {
    console.log(e.keyCode)
    const val = e.target.value;
    this.setState({ messageText: val });
    if (e.keyCode == 13 && e.shiftKey == false) {
      this.emitMessage(e)
    }
  }



  componentWillMount() {
    console.log("oooser-->", this.state.user)
    if(this.state.user && this.state.user.agentType == "leadAgent"){
      this.props.history.push('/agent/lead-dashboard/1')
      return
    }

    this.unlisten = this.props.history.listen((location, action) => {
      let url = location.pathname.split('/')

      if (url != undefined && url[4] != undefined) {
        this.setState({
          clientId: url[4]
        })
        this.getChatUsers(1, this.state.search, url[4])
        this.getMessages();

      }
    });

    botService.getButtons()
      .then(res => {
        if (res.statusCode === 200) {
          this.setState(() => ({
            buttons: res.data.result,
          }));
          // this.setLoadingToggle(false);
        }
      }).catch(error => {
        // this.setLoadingToggle(false);
      });

  }

  async componentDidMount() {
    var hrefSplit = window.location.href.split('?')
    console.log(hrefSplit)
    if (hrefSplit.length > 1) {
      window.location.href = hrefSplit[0]
    }

    console.log('componentWillMount')
    document.title = "Voiceoc | Chat";
    //this.detectChnageURl();

    this.getChatUsers(1, this.state.search, this.props.user._id)

    var jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {

      socket = socketIOClient(ENDPOINT, { query: `authorization=${jwtToken}` })
      console.log('this.props.match.params.userId', this.props.match.params.userId)
      if (socket && !socket.connected && this.props.match.params.userId === undefined) {
        await socket.once('connect', () => {
          console.log('connect', socket);
        });
      }


      socket.on("chatting", data => {
        console.log('chatting', data)
        if (data.event === 'receive-message') {
          audio.play()
          this.getChatUsers(this.props.match.params.page, this.state.search, this.props.user._id, true)
        }
        else if (data.event === 'initiate-chat') {//
          audio.play()
          this.getChatUsers(this.props.match.params.page, this.state.search, this.props.user._id, true)
          this.engageNumberApi()
        }
        else if (data.event === 'end-chat') {//
          audio.play()
          this.getChatUsers(this.props.match.params.page, this.state.search, this.props.user._id, true)
          this.engageNumberApi()
        }

      });
      this.engageNumberApi();
    }
  }

  async engageNumberApi() {
    let engageData = await clientService.getCount();
    this.setState({
      engageNumber: engageData.data.engageNumbers
    })
  }
  handleScroll(event) {
    let self = this;
    if (event.target.scrollTop === 0) {
      //console.log('abc',event.target.scrollTop)
      let activePage = this.state.page + parseInt(1);
      this.getMessages(activePage)
    }
  }

  getFilterChatUser() {
    const id = this.props.match.params.userId;
    var chatUser = this.state.chatUsers.filter(function (user) {
      return user && user.customerDetail && user.customerDetail[0] && user.customerDetail[0]._id === id
    });
    if (chatUser.length) {
      let formDisabled = false;
      if (chatUser && chatUser[0].status && chatUser[0].status === 'deactive') {
        formDisabled = true
      }
      this.setState({
        selectChatUser: chatUser[0],
        formDisabled
      })
    }
    return '';
  }

  async getMessages(page = 1) {

    if (this.state.selectChatUser && this.state.selectChatUser.customerDetail && this.state.selectChatUser.customerDetail[0]) {

      let chatId = this.state.selectChatUser.customerDetail[0].mobileNumber;

      await clientService.getAllMessages(chatId, page)
        .then(async (res) => {
          if (res.statusCode === 200) {
            if (res.data.result && res.data.result.length) {
              var allMessage = (res.data.result.reverse()).concat(this.state.messages);

              await this.setState(() => ({
                messages: allMessage,
                page: page
              }));
            }
            if (page === 1) {
              await this.setState(() => ({
                messages: res.data.result,
                page: 1
              }));
              this.scrollToBottom();
            }

            if (page > 1 && res.data.result && res.data.result.length) {
              this.chatDiv.current.scrollTop = 100
            }
          }
        })

      // console.log('this.state.selectChatUser',this.state.selectChatUser)
      // return false;
      // clientService.getMessages(chatId, page)
      //   .then(res => {
      //     if (res.statusCode === 200) {
      //       if (res.data.result && res.data.result.length) {
      //         var allMessage = (res.data.result.reverse()).concat(this.state.messages);
      //         this.setState(() => ({
      //           messages: allMessage,
      //           page: page
      //         }));
      //       }



      //       if (page === 1) {
      //         this.setState(() => ({
      //           messages: res.data.result,
      //           page: 1
      //         }));
      //         this.scrollToBottom();
      //       }
      //       if (page > 1 && res.data.result && res.data.result.length) {
      //         this.chatDiv.current.scrollTop = 100
      //       }
      //     }
      //   })
    }
  }

  scrollToBottom = () => {
    if (this.chatDiv.current) {
      this.chatDiv.current.scrollTop = this.chatDiv.current.scrollHeight;
    }
  };

  getChatUsers(page, search, clientId, highlited = false, sortKey = false, sortType = false) {
    clientService.getChatUsers(page, search, 50, false, sortKey, sortType)
      .then(async res => {
        if (res.statusCode === 200) {

          let dataArray = res.data.result;
          if (highlited && dataArray.length > 0) {
            dataArray[0]['highlited'] = true;
          }
          await this.setState(() => ({
            chatUsers: res.data.result,
            nextPage: res.data.next,
            totalRecords: res.data.total,
            prevoiusPage: this.state.currentPage > 1 ? true : false,
          }));
          this.getFilterChatUser();
          if (this.props.match.params && this.props.match.params.userId != undefined) {
            this.getMessages()
          }
        }
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.statusCode === 201) {
          this.props.logOut();
          this.props.history.push("/login");
        }
        console.log(error);

      });
  }

  getFilterAdmin(id) {
    var admins = this.state.adminOptions.filter(function (admin) {
      return admin._id === id
    });
    if (admins.length) {
      return admins[0]
    }
    return '';
  }

  detectChnageURl() {
    if (this.props.match.params && this.props.match.params.id != undefined) {
      let label = this.getFilterAdmin(this.props.match.params.id);
      this.setState({
        selectedAdmin: { value: label._id, label: label.name }
      })
    }

    if (this.props.match.params && this.props.match.params.clientId != undefined) {
      this.setState({
        clientId: this.props.match.params.clientId
      })
    }
  }

  //listen client change
  selectAdmin = selectedAdmin => {
    this.props.history.push('/admin/dashboard/' + selectedAdmin.value + '/1');
  }

  formatDate(date) {
    return Moment(date).format('DD/MM/YY h:mm a');
  }

  getPagination() {
    var page = this.props.match.params.page;
    var startRecord = this.props.match.params.page == 1 ? this.props.match.params.page : ((50 * (this.props.match.params.page - 1)) + 1)
    var endRecord = (parseInt(startRecord) + parseInt(this.state.chatUsers.length) - 1);
    return startRecord + '-' + endRecord
    //+' of '+this.state.totalRecords;
  }

  async selectUserToChat(e, user) {

    await this.setState({
      selectChatUser: user
    })

    if (user && user.customerDetail && user.customerDetail.length)
      this.props.history.push('/agent/dashboard/1/' + user.customerDetail[0]._id);
    else {
      alert('No customer details were found')
    }

  }

  async uploadFile() {
    let filesData;
    clientService.uploadFile(this.state.selectedFile)
      .then(response => {
        const filesData = response.data;


      })
      .catch(error => {
        console.log(error);

      });
    return filesData
  }

  async emitMessage(event) {
    event.preventDefault();
    if (!this.state.selectChatUser) {
      toast.success('Please first select the user.');
      return false;
    }
    let customerDetail = this.state.selectChatUser.customerDetail[0]
    let emitData = {
      event: 'send-message',
      data: {
        chatId: this.state.selectChatUser._id,
        mobileNumber: this.state.selectChatUser.customerDetail[0].mobileNumber,
        body: this.state.messageText,
        media: [],
        customerDetail
      }
    }
    if (this.state.selectedFile) {
      clientService.uploadFile(this.state.selectedFile)
        .then(response => {
          const filesData = response.data;
          emitData.data.media.push(filesData)
          console.log(emitData)
          socket.emit("chat", emitData);
          this.setState({ messageText: '', selectedFile: null })
          document.getElementById("chatForm").reset();
          this.getChatUsers(this.props.match.params.page, this.state.search, this.props.user._id, true)
          this.getMessages();
        })
        .catch(error => {
          console.log(error);
        });
    }
    else {
      if (this.state.messageText == '' || this.state.messageText == null) {
        toast.error('Please Type something.');
        return false;
      }
      socket.emit("chat", emitData);
      console.log('emitmessage', socket)
      this.setState({ messageText: '' })
      document.getElementById("chatForm").reset();
      this.getChatUsers(this.props.match.params.page, this.state.search, this.props.user._id, true)
      this.getMessages();
    }
  };

  onChangeFile = (event) => {
    event.preventDefault();
    if (!this.state.selectChatUser) {
      toast.success('Please first select the user.');
      return false;
    }
    const data = new FormData();
    data.append('media', event.target.files[0]);
    this.setState({ selectedFile: data, fileName: event.target.files[0].name });
  }

  chatEnd(e, selectChatUser) {


    if (!selectChatUser) {
      toast.success('Please first select the user.');
      return false;
    }
    let chatEndData = {
      chatId: selectChatUser._id,
      mobileNumber: selectChatUser.customerDetail[0].mobileNumber
    }

    clientService.endChat(chatEndData)
      .then(response => {

        toast.success('Chat end successfully');
        this.setState({
          formDisabled: true
        })
        this.getChatUsers(this.props.match.params.page, this.state.search, this.props.user._id)
        this.engageNumberApi();
      })
      .catch(error => {
        console.log(error);
      });
  }

  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      console.log(e.keyCode, e.shiftKey, 'eeeeeee')
    }
  }

  orderByChatList(e) {

    if (e.target.value === 'latest') {
      this.getChatUsers(1, this.state.search, false, false)
    } else {
      var valueKey = e.target.value.split('_');
      this.getChatUsers(1, this.state.search, false, false, valueKey[1], valueKey[0])
    }

  }

  getButtonData = (data) => {
    console.log('data==', data)
    this.setState({ messageText: data });
  }

  async handleSearch(event) {
    await this.setState({
      search: event.target.value
    })
    this.getChatUsers(this.props.match.params.page, this.state.search, false, false)
    // this.props.history.push("/admin/all-chat/" + 1);
  }
  render() {
    const { selectChatUser } = this.state;
    const { nextPage } = this.state;
    const { chatUsers } = this.state;
    const { messages } = this.state;
    const { messageText } = this.state;
    const { buttons } = this.state;
    console.log('buttons', buttons);
    let settings = {
      dots: true,
      autoplay: false,
      infinite: true,
      speed: 500,
      slidesToShow: buttons.length < 4 ? buttons.length : 4,
      slidesToScroll: 1
    };


    return (
      <SiteWrapperAdmin>
        <Page.Content>
          <audio id="audio"><source src="/notification_tune.mp3" type="audio/mp3"></source></audio>

          <div>
            <Grid.Row >

              <div className="page-header">

                <div className="col col-sm-12 row ascv" >
                  <div className="d-flex col-sm-4">
                    <select className=" custom-select    " onChange={(e) => this.orderByChatList(e)}>
                      <option value="latest">

                        {changeLanguage(this.props.siteLanguage, 'time_latest_first')}
                      </option>
                      <option value="asc_name">
                        {changeLanguage(this.props.siteLanguage, 'name_a_z')}
                      </option>
                      <option value="desc_name">
                        {changeLanguage(this.props.siteLanguage, 'name_z_a')}
                      </option>


                    </select>

                  </div>


                  <div className="col col-sm-4 ">
                    <div className="form-control">
                      Active Chats : {` ${this.state.engageNumber}`}
                    </div>

                  </div>


                  <div className="col col-sm-4 engage-bo ">

                    <input name="search" className="form-control" type="text"
                      placeholder="Search"
                      value={this.state.search}

                      onChange={(e) => this.handleSearch(e)}
                    />



                  </div>












                </div>

              </div>


            </Grid.Row>
            {/* <Grid.Row>
           
            </Grid.Row> */}
            <Grid.Row >

              <div className="col col-sm-6">
                {/* //display button col-sm-5 */}

                <Card className="card01a">


                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter  tablebackground"
                  >
                    <Table.Header>
                      <Table.Row>

                        <Table.ColHeader className="capitilize fontSize"> </Table.ColHeader>

                        <Table.ColHeader className="capitilize fontSize "> {changeLanguage(this.props.siteLanguage, 'name')}</Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize ">{changeLanguage(this.props.siteLanguage, 'date')}</Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize ">{changeLanguage(this.props.siteLanguage, 'mobile_number')}</Table.ColHeader>

                        <Table.ColHeader className="capitilize fontSize">Context </Table.ColHeader>
                        {/* <Table.ColHeader className="capitilize fontSize">Hospital </Table.ColHeader> */}
                        {/* <Table.ColHeader className="capitilize fontSize">City</Table.ColHeader> */}
                        <Table.ColHeader className="capitilize fontSize">Language</Table.ColHeader>

                        <Table.ColHeader className="capitilize fontSize"></Table.ColHeader>



                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {chatUsers.length ?
                        chatUsers.filter(user=>user && user.customerDetail && user.customerDetail[0]).map((user, childIndex) => (

                          <tr key={childIndex}
                            className={
                              "" +
                              (selectChatUser && user.customerId === selectChatUser.customerId ? "highlitedtr" : "") + (user.status === 'active' ? " boldtd" : "") +
                              " "
                            }
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => this.selectUserToChat(e, user)}
                          >
                            <td className="w-1 sticky-col">
                              {
                                user.highlited ?
                                  <span className="avatar1">
                                  </span>
                                  :
                                  null
                              }


                            </td>
                            <td className="cursorPointer sticky-col aligntd"
                            >

                              {user.status === 'active' ?
                                <span>
                                  {user && user.customerDetail && user.customerDetail.length > 0 && user.customerDetail[0].name}
                                </span>
                                :
                                <span>
                                  {user && user.customerDetail && user.customerDetail.length > 0 && user.customerDetail[0].name}
                                </span>
                              }

                            </td>
                            <td className="aligntd">
                              {this.formatDate(user.updatedAt)}
                            </td>

                            <td className="aligntd">
                                  
                            {user && user.customerDetail && user.customerDetail.length > 0 &&
                              !isNaN(parseInt(user.customerDetail[0].mobileNumber))
                                ? user.customerDetail[0].mobileNumber
                                : (user && user.customerDetail && user.customerDetail.length > 0 &&
                                    user.customerDetail[0].entity &&
                                    user.customerDetail[0].entity.length > 0 &&
                                    user.customerDetail[0].entity.find(obj => obj.entity === 'mobile_number')
                                  )
                                ? (!isNaN(parseInt(user.customerDetail[0].entity.find(obj => obj.entity === 'mobile_number').value))
                                  ? user.customerDetail[0].entity.find(obj => obj.entity === 'mobile_number').value
                                  : "Not Applicable")
                                : "Not Applicable"
                            }
                            </td>



                            <td className="">
                              {user && user.customerDetail && user.customerDetail.length > 0 && user.customerDetail[0].context}
                            </td>
                            {/* <td className="">
                              {user && user.customerDetail && user.customerDetail.length > 0 &&


                                user.customerDetail[0].hospital.charAt(0).toUpperCase() + user.customerDetail[0].hospital.slice(1)
                              }
                            </td> */}

                            {/* <td className="">
                              { user && user.customerDetail && user.customerDetail.length>0 && user.customerDetail[0].city}
                            </td> */}
                            <td className="">
                              {user && user.customerDetail && user.customerDetail.length > 0 &&
                                user.customerDetail[0].langugae && user.customerDetail[0].langugae.charAt(0).toUpperCase() + user.customerDetail[0].langugae.slice(1)
                              }
                            </td>

                            <td className="w-1">
                              <a className="icon"
                                onClick={(e) => this.openInfoPopup(e, user.customerDetail[0])}>
                                { user && user.customerDetail && user.customerDetail.length > 0 && user.customerDetail[0].entity.length > 0 ?
                                  <i className="fe fe-info"></i>
                                  :
                                  null
                                }
                              </a>
                            </td>
                          </tr>
                        ))
                        :
                        <Table.Row>
                          <Table.Col colSpan={7} >Sorry!  no data found.</Table.Col>
                        </Table.Row>
                      }

                    </Table.Body>
                  </Table>
                  {/* pagination */}
                  <div className="form-group" style={{ float: 'right' }}>
                    <label className="form-label">

                    </label>
                    <div className="selectgroup align-to-right paginations">
                      <span>



                        {this.getPagination()}
                      </span>

                      <i
                        className={
                          "fe " +
                          (this.props.match.params.page <= 1 ? "not-active " : "cursorPointer ") +
                          " fe-chevron-left"
                        }
                        onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) - 1))}
                        disabled={this.props.match.params.page <= 1}>
                      </i>

                      <i
                        className={
                          "fe " +
                          (!nextPage ? "not-active " : "cursorPointer ") +
                          " fe-chevron-right"
                        }

                        onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) + 1))}
                        disabled={!nextPage}></i>
                    </div>
                  </div>
                </Card>


              </div>

              <div className="col col-sm-6 topChatBoc">

                <div className="card card2">
                  <div className="card-header1">
                    <h3 className="card-title card-t1">
                      {
                        selectChatUser && selectChatUser.customerDetail && selectChatUser.customerDetail.length ?
                          selectChatUser.customerDetail[0].name
                          : changeLanguage(this.props.siteLanguage, 'chat')
                      }


                    </h3>
                    <div className="card-options">

                      <button
                        className={
                          " bg-black marginChatBox " +
                          (this.state.formDisabled ? " disablebutton" : " ") +
                          ""
                        }


                        onClick={(e) => { if (window.confirm('Are you sure to end chat?')) { this.chatEnd(e, selectChatUser) }; }}

                        disabled={this.state.formDisabled}
                      > {changeLanguage(this.props.siteLanguage, 'end_chat')} {this.state.formDisabled}</button>

                    </div>
                  </div>
                  <div className="card-body card1"
                    ref={this.chatDiv}

                    onScroll={(e) => this.handleScroll(e)}
                  >
                    <ul className="list-unstyled 
                    list-separated">
                      {
                        messages && messages.length ?
                          messages.map((message, childIndex) => (
                            <div key={childIndex +'_'+ message._id}>
                              {
                                (message.messageFrom === 'user' || message.messageFrom === 'customer') ?
                                  <li className="list-separated-item">
                                    <div className="row 
                      row align-items-center botchat">
                                      <div className="col col-auto">
                                        <span className="avatar avatar-md 
                    d-block usertextcolor 
                      userbackground1">
                                          C</span></div>
                                      <div className="col"><div>
                                        <span className="text-inherit" >
                                          {
                                            message
                                                && Array.isArray(message.media)
                                                && message.media.length > 0
                                                && message.media[0].mimeType !== null
                                                && message.media[0].mimeType.split('/')[0] === 'image'
                                                // || message.media[0].mimeType === 'image/jpg' 
                                                // || message.media[0].mimeType === 'image/png'
                                                ? (<div>
                                                  <img src={message.media[0].url} />
                                                    <p>{message.message}</p>
                                                </div>) :
                                                (
                                                  message
                                                    && Array.isArray(message.media)
                                                    && message.media.length > 0
                                                    ? 
                                                    <a target="_blank" href={message.media[0].url}>
                                                      {message.media[0].originalName}
                                                    </a>
                                                    : message.message)

                                          }
                                          <p >
                                            {this.formatDate(message.createdAt)}
                                          </p>
                                        </span></div>
                                        <div></div>
                                      </div>

                                    </div>
                                  </li>

                                  :


                                  <li className="list-separated-item">
                                    <div className="row row 
                    align-items-center">



                                      <div className="col">
                                        <div className="col01">
                                          <span className="text-inherit">
                                            {
                                              message
                                                && Array.isArray(message.media)
                                                && message.media.length > 0
                                                && message.media[0].mimeType !== null
                                                && message.media[0].mimeType.split('/')[0] === 'image'
                                                // || message.media[0].mimeType === 'image/jpg' 
                                                // || message.media[0].mimeType === 'image/png'
                                                ? (<div>
                                                  <img src={message.media[0].url} />
                                                    <p>{message.message}</p>
                                                </div>) :
                                                (
                                                  message
                                                    && Array.isArray(message.media)
                                                    && message.media.length > 0
                                                    ? <a target="_blank" href={message.media[0].url}>
                                                      {message.media[0].originalName}
                                                    </a>
                                                    : message.message
                                                )

                                            }

                                            <p >
                                              {this.formatDate(message.createdAt)}
                                            </p>
                                          </span></div></div>

                                      <div className="col col-auto">
                                        <span className="avatar avatar-md 
                      d-block usertextcolor 
                        userbackground">C</span>
                                      </div>


                                    </div>
                                  </li>




                              }
                            </div>
                          ))
                          :
                          null
                      }
                    </ul>
                  </div>
                  <div className="car18">
                    <div className="button-slider">
                      <Slider {...settings}>

                        {
                          buttons && buttons.map((value) => {
                            let textValue = value.text;
                            return <div key={value._id} >
                              <h3 title={textValue} onClick={() => this.getButtonData(textValue)}>{value.name}</h3>
                              {/* //enter value heare.. */}
                            </div>
                          })
                        }


                        {/* <div>
        <h3 onClick = {()=> this.getButtonData(2)}>Good Morning</h3>
      </div>
      <div>
        <h3 onClick = {()=> this.getButtonData(3)}>Help Message</h3>
      </div>
      <div>
        <h3>Welcome</h3>
      </div>
      <div>
        <h3>Change in TAT</h3>
      </div> */}
                        {/* <div>
        <h3>Report Unavailable</h3>
      </div> */}
                      </Slider>

                    </div>
                    <form id="chatForm">

                      <Form.Group label="">

                        <div className="form-group"><textarea className="form-control" name="messageText"

                          placeholder="Type"
                          value={this.state.messageText}
                          onChange={(e) => this.handleChange(e, 'messageText')}
                          onKeyDown={(e) => this.handleChange(e, 'messageText')}
                          disabled={this.state.formDisabled}
                        ></textarea></div>
                      </Form.Group>

                      <div className="form-group">
                        <i className="fe fe-paperclip"></i>
                        <input type="file"
                          disabled={this.state.formDisabled}
                          name="file" id="file" className="upload-file"
                          onChange={(e) => this.onChangeFile(e)} />
                        <button className="btn btn-primary pull-right"
                          disabled={this.state.formDisabled}
                          onClick={(e) => this.emitMessage(e)}
                        >

                          {changeLanguage(this.props.siteLanguage, 'send_message')}

                        </button>

                      </div>
                    </form>
                    <div className="pull-right dis-non">
                      <span className="stamp stamp-md  bg-1 marginBottom10"
                        onClick={(e) => this.openPopup(e, '123')}
                      ><i className="fe fe-dollar-sign"></i></span>
                      <span className="stamp stamp-md  bg-2   marginBottom10"
                        onClick={(e) => this.openPopup(e, '123')}><i className="fe fe-shopping-cart"></i></span>
                      <span className="stamp stamp-md bg-3   marginBottom10"><i className="fe fe-users" onClick={(e) => this.openPopup(e, '123')}></i></span>
                      <span className="stamp stamp-md bg-4   marginBottom10" onClick={(e) => this.openPopup(e, '123')}><i className="fe fe-message-square"></i></span>
                      <span className="stamp stamp-md btn bg-5   marginBottom10" onClick={(e) => this.openPopup(e, '123')}><i className="fe fe-shopping-cart"></i></span>
                      <span className="stamp stamp-md btn bg-6   marginBottom10" onClick={(e) => this.openPopup(e, '123')}><i className="fe fe-dollar-sign"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <ReadmorePopup
                openPopup={this.state.openPopup}
                onChange={this.closePopup}
              ></ReadmorePopup>
              <InfoPopup
                openInfoPopup={this.state.openInfoPopup}
                onChange={this.closeInfoPopup}
              ></InfoPopup>
            </Grid.Row>

          </div>

        </Page.Content>
        <ToastContainer autoClose={5000} position={'bottom-right'} />
      </SiteWrapperAdmin >
    )
  }

}


const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    activeBot: state.auth.activeBot,
    siteLanguage: state.auth.siteLanguage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout())
    },
    setLang: (payload) => {
      dispatch(authActions.setLang(payload))
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDashboard));