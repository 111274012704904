// @flow

import React from "react";
import { Form, Grid, Card, Button, Icon, Page } from "tabler-react";
import Select from "react-select";
import clientService from "../../../services/client";
import { validate } from "../../../validate";
import { toast, ToastContainer } from "react-toastify";
import Buttonloader from "../../../common/Loader/Buttonloader";
import "react-toastify/dist/ReactToastify.css";
import { changeLanguage } from "../../../language";
import {
  getAllCategories,
  getCampaignById,
  updateCampaign,
  updateCampaignImage,
  updateCampaignImageAr,
} from "../../../services/campaign";
import SiteWrapperCampaignSuperAdmin from "../SiteWrapper.campaign.superadmin.react";

const validationRules = [
  {
    field: "name_en",
    validations: ["required"],
    name: "Name",
  },
  {
    field: "name_kn",
    validations: ["required"],
    name: "Arabic Name",
  },
  {
    field: "categoryId",
    validations: ["required"],
    name: "Category",
  },
];

class CampaignEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name_en: "",
        name_kn: "",
        text_data_en: "",
        text_data_kn: "",
        categoryId: "",
        _id: "",
      },
      message: {
        style: "success",
        text: "",
      },
      fileObj: null,
      fileObjAr: null,
      image_data: "",
      image_data_kn: "",
      isLoading: false,
      categoryArr: [],
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value });
  }

  //handle change
  handleChange = (e, field) => {
    const { fields } = this.state,
      val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    this.validate();
  };

  onChangeFileUser = (event) => {
    // debugger
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/jpg" ||
      event.target.files[0].type === "image/png"
    ) {
      // data.append('clientId', this.props.match.params.clientId);
      this.setState({ fileObj: event.target.files[0] });
    } else {
      toast.error("Please select only image");
    }
  };

  onChangeFileUserAr = (event) => {
    // debugger
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/jpg" ||
      event.target.files[0].type === "image/png"
    ) {
      // data.append('clientId', this.props.match.params.clientId);
      this.setState({ fileObjAr: event.target.files[0] });
    } else {
      toast.error("Please select only image");
    }
  };

  handleNotification() {
    this.setState(() => ({ message: { text: "", style: "" } }));
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Edit Category";
    this.setLoadingToggle(true);

    getAllCategories()
      .then((res) => {
        console.info("catres", res);
        this.setState(() => ({
          categoryArr: res.data.result,
        }));
      })
      .catch((error) => {
        if (error.response && error.response.data.message)
          toast.error(error.response.data.message);
        else if (error.message) toast.error(error.message);
        else toast.error(error);
      });

    getCampaignById(this.props.match.params.id)
      .then((res) => {
        console.log("res", res);
        this.setState({
          fields: {
            _id: res.data._id,
            categoryId: res.data.categoryId,
            name_en: res.data.name_en,
            name_kn: res.data.name_kn,
            text_data_en: res.data.text_data_en,
            text_data_kn: res.data.text_data_kn,
          },
          image_data: res.data.image_data,
          image_data_kn: res.data.image_data_kn,
        });
        this.setLoadingToggle(false);
      })
      .catch((error) => {
        if (error.response && error.response.data.message)
          toast.error(error.response.data.message);
        else if (error.message) toast.error(error.message);
        else toast.error(error);
        this.setLoadingToggle(false);
      });
  }

  //submit form
  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.validate()) {
      this.setLoadingToggle(true);
      let data = {
        name_en: this.state.fields.name_en,
        name_kn: this.state.fields.name_kn,
        categoryId: this.state.fields.categoryId,
        text_data_kn: this.state.fields.text_data_kn,
        text_data_en: this.state.fields.text_data_en,
      };
      updateCampaign(data, this.props.match.params.id)
        .then((response) => {
          this.setLoadingToggle(false);
          toast.success("Campaign updated successfully.");
          if (this.state.fileObj) {
            let formData = new FormData();
            formData.append("media", this.state.fileObj);
            updateCampaignImage(formData, this.props.match.params.id)
              .then((res) => {
                console.log(res);
                toast.success("Campaign Image uploaded successfully.");
              })
              .catch((error) => {
                this.setLoadingToggle(false);
                if (error.response && error.response.data.message)
                  toast.error(error.response.data.message);
                else if (error.message) toast.error(error.message);
                else toast.error(error);
              });
          }

          if (this.state.fileObjAr) {
            let formData = new FormData();
            formData.append("media", this.state.fileObjAr);
            updateCampaignImageAr(formData, this.props.match.params.id)
              .then((res) => {
                console.log(res);
                toast.success("Telugu Campaign Image uploaded successfully.");
              })
              .catch((error) => {
                this.setLoadingToggle(false);
                if (error.response && error.response.data.message)
                  toast.error(error.response.data.message);
                else if (error.message) toast.error(error.message);
                else toast.error(error);
              });
          }

          setTimeout(() => {
            this.props.history.push("/admin/campaigntabs/campaign/1");
          }, 3000);
        })
        .catch((error) => {
          this.setLoadingToggle(false);
          if (error.response && error.response.data.message)
            toast.error(error.response.data.message);
          else if (error.message) toast.error(error.message);
          else toast.error(error);
        });
    }
  };

  render() {
    return (
      <SiteWrapperCampaignSuperAdmin>
        <Page.Content>
          <Grid.Row>
            <Grid.Col lg={5} className="mx-auto ">
              <Card>
                <Card.Header>
                  <Card.Title>Edit Campaign</Card.Title>
                </Card.Header>
                <Card.Body>
                  <form onSubmit={this.handleSubmit}>
                    <Form.FieldSet>
                      <Form.Group
                        isRequired
                        label={changeLanguage(
                          this.props.siteLanguage,
                          "name_en"
                        )}
                      >
                        <Form.Input
                          name="name_en"
                          onChange={(e) => this.handleChange(e, "name_en")}
                          placeholder={changeLanguage(
                            this.props.siteLanguage,
                            "name_en"
                          )}
                          value={this.state.fields.name_en}
                        />
                        <label
                          style={{
                            display: this.state.errors.name_en
                              ? "block"
                              : "none",
                          }}
                          className="error"
                        >
                          {this.state.errors.name_en}
                        </label>
                      </Form.Group>

                      <Form.Group
                        isRequired
                        label={changeLanguage(
                          this.props.siteLanguage,
                          "name_kn"
                        )}
                      >
                        <Form.Input
                          name="name_kn"
                          onChange={(e) => this.handleChange(e, "name_kn")}
                          placeholder={changeLanguage(
                            this.props.siteLanguage,
                            "name_kn"
                          )}
                          value={this.state.fields.name_kn}
                        />
                        <label
                          style={{
                            display: this.state.errors.name_kn
                              ? "block"
                              : "none",
                          }}
                          className="error"
                        >
                          {this.state.errors.name_kn}
                        </label>
                      </Form.Group>

                      <Form.Group
                        isRequired
                        label={changeLanguage(
                          this.props.siteLanguage,
                          "category"
                        )}
                      >
                        <Form.Select
                          name={changeLanguage(
                            this.props.siteLanguage,
                            "category"
                          )}
                          onChange={(e) => this.handleChange(e, "categoryId")}
                          value={this.state.fields.categoryId}
                        >
                          <option value="">
                            {changeLanguage(
                              this.props.siteLanguage,
                              "please_select_category"
                            )}
                          </option>
                          {this.state.categoryArr &&
                            this.state.categoryArr.length &&
                            this.state.categoryArr.map((el) => (
                              <option key={el._id} value={el._id}>
                                {el.name_en}
                              </option>
                            ))}
                        </Form.Select>
                        <label
                          style={{
                            display: this.state.errors.categoryId
                              ? "block"
                              : "none",
                          }}
                          className="error"
                        >
                          {this.state.errors.categoryId}
                        </label>
                      </Form.Group>
                      <h5>Data : </h5>
                      <Form.Group
                        label={changeLanguage(
                          this.props.siteLanguage,
                          "text_data_en"
                        )}
                      >
                        <Form.Input
                          name="text_data_en"
                          onChange={(e) => this.handleChange(e, "text_data_en")}
                          placeholder={changeLanguage(
                            this.props.siteLanguage,
                            "text_data"
                          )}
                          value={this.state.fields.text_data_en}
                        />
                        <label
                          style={{
                            display: this.state.errors.text_data_en
                              ? "block"
                              : "none",
                          }}
                          className="error"
                        >
                          {this.state.errors.text_data_en}
                        </label>
                      </Form.Group>

                      <Form.Group
                        label={changeLanguage(
                          this.props.siteLanguage,
                          "text_data_kn"
                        )}
                      >
                        <Form.Input
                          name="text_data_kn"
                          onChange={(e) => this.handleChange(e, "text_data_kn")}
                          placeholder={changeLanguage(
                            this.props.siteLanguage,
                            "text_data_kn"
                          )}
                          value={this.state.fields.text_data_kn}
                        />
                        <label
                          style={{
                            display: this.state.errors.text_data_kn
                              ? "block"
                              : "none",
                          }}
                          className="error"
                        >
                          {this.state.errors.text_data_kn}
                        </label>
                      </Form.Group>

                      <h6>
                        {changeLanguage(this.props.siteLanguage, "image_data")}:
                      </h6>

                      {this.state.image_data && (
                        <img
                          src={this.state.image_data}
                          alt="image_data"
                          style={{ maxWidth: 300, maxHeight: 300, margin: 5 }}
                        />
                      )}
                      <div className="file">
                        <span className="file-custom">
                          <input
                            name="example-text-input"
                            type="file"
                            onChange={(e) => this.onChangeFileUser(e)}
                          />
                        </span>
                      </div>

                      <h6>
                        {changeLanguage(
                          this.props.siteLanguage,
                          "image_data_kn"
                        )}
                        :
                      </h6>

                      {this.state.image_data_kn && (
                        <img
                          src={this.state.image_data_kn}
                          alt="image_data_kn"
                          style={{ maxWidth: 300, maxHeight: 300, margin: 5 }}
                        />
                      )}
                      <div className="file">
                        <span className="file-custom">
                          <input
                            name="example-text-input"
                            type="file"
                            onChange={(e) => this.onChangeFileUserAr(e)}
                          />
                        </span>
                      </div>
                    </Form.FieldSet>
                    {!this.state.isLoading ? (
                      <Button color="primary btn-block" type="submit">
                        Update
                      </Button>
                    ) : (
                      <Buttonloader
                        loading={this.state.isLoading}
                      ></Buttonloader>
                    )}
                  </form>
                  <ToastContainer autoClose={5000} position={"bottom-right"} />
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapperCampaignSuperAdmin>
    );
  }
}

export default CampaignEdit;
