// @flow

import * as React from "react";
import { NavLink, withRouter, Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import {
  Site,
  RouterContextProvider,
} from "tabler-react";
import authActions from "../redux/auth/action";
// import type { NotificationProps } from "tabler-react";

const navBarItems = [
  {
    value: "Dashboard",
    to: "/admin/dashboard",
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: "Agents",
    icon: "user",
    subItems: [
      {
        value: "View Agents",
        to: "/admin/clients/1",
        LinkComponent: withRouter(NavLink),
      },
      {
        value: "Add New Agent",
        to: "/admin/client/add",
        LinkComponent: withRouter(NavLink),
      },
    ],
  },
  
];

class SiteWrapperAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
  }

  routeChange(path) {
    if (path === '/login') {
      this.props.logOut();
    }
    this.props.history.push(path);
  }

  componentDidMount() {
    //const { user } = this.props;
    // if (user.role === "client") {
    //   this.props.history.push('/client/dashboard');
    // }
    // this.props.logOut();
    //this.props.history.push('/');
  }

  render() {
    const { user } = this.props;
    const redirectUrl = user.role === 'admin' ? "/agent/lead-dashboard/1" : '';
    return (
      user.role === 'admin' ? <Redirect to={redirectUrl} /> :
        <Site.Wrapper
          headerProps={{
            to: '/',
            alt: "Voiceoc",
            imageURL: process.env.REACT_APP_publicUrl + '/images/logo.png',
            accountDropdown: {
              //avatarURL: process.env.REACT_APP_publicUrl + "/images/default.jpeg",
              name: user.name,
              description: "Administrator",
              options: [
                {
                  icon: "circle", value: "Change Password", onClick: () => {
                    this.routeChange('/admin/change-password');
                  }
                },
                { isDivider: true },
                {
                  icon: "settings", value: "Settings", onClick: () => {
                    this.routeChange('/admin/settings');
                  }
                },
                { isDivider: true },
                {
                  icon: "log-out", value: "Sign out", onClick: () => {
                    this.routeChange('/login');
                  }
                },
              ],
            },
          }}
          navProps={{ itemsObjects: navBarItems }}
          routerContextComponentType={withRouter(RouterContextProvider)}
          footerProps={{
            copyright: (
              <React.Fragment>
                Copyright © 2020

              <Link to="/"> Voiceoc Innovations Pvt Ltd. </Link>
                All Rights Reserved
            </React.Fragment>
            ),
          }}
        >
          {this.props.children}
        </Site.Wrapper >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}


function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout())
    },
    setLang: (payload) => {
      dispatch(authActions.setLang(payload))
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteWrapperAdmin));

