// @flow

import React from "react";
import {
  Button,
  Grid,
  Card,
  Table,
  Form,
  Page,
  Icon
} from "tabler-react";
import Select from 'react-select';
import { Link, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import SiteWrapper from "./SiteWrapper.client.react";
import clientService from "../../services/client";
import userService from "../../services/user";
import authActions from "../../redux/auth/action"
import C3Chart from "react-c3js";
import '../../c3jscustom.css';
import 'c3/c3.css';
import * as d3 from 'd3'
import ReactWordcloud from 'react-wordcloud';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import words from "./words";
const options = {
  rotations: 2,
  rotationAngles: [0, 0],
};
class FeedBackAnalytics extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
        openDownloadPopup: false,
      
        startDateSingleBarHorizontal: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        endDateSingleBarHorizontal: new Date(),
        filterStartDateSingleBarHorizontal: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US'),
        filterEndDateSingleBarHorizontal: new Date().toLocaleDateString('en-US'),

        startDateWord: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        endDateWord: new Date(),
        filterStartDateWord: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US'),
        filterEndDateWord: new Date().toLocaleDateString('en-US'),

        startDateFour: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        endDateFour: new Date(),
        filterStartDateFour: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US'),
        filterEndDateFour: new Date().toLocaleDateString('en-US'),

        allNotificationAgentData: [],
        notificationAgentData: [],
        notificationAgentXAxix: [],
        allData:[],
        distarctors :'',
        passives: '',
        promotors:'',
        wordData:[],
        distribution:[],
        neutral:[],
        negetive:[],
        positive:[],
        month:[],
        monthValue:new Date().getFullYear().toString(),
        department:'',
        source:this.props.source,
        departmentArray:[]
    }


    // this.closeDownloadPopup = this.closeDownloadPopup.bind(this);
  }

  componentDidMount() {
    this.callNotificationServiceAgent(this.state.filterStartDateSingleBarHorizontal, this.state.filterEndDateSingleBarHorizontal,'agent')
    this.getWordCount(this.state.filterStartDateWord,this.state.filterEndDateWord)
    this.getHospitalList()
    
  this.getDistribution(this.state.monthValue,'')
  }
  async getDistribution (year,department) {
    // let department = "Housekeeping"
    await   clientService.getDistribution(year,department,this.state.source).
    then((res)=>{
      // let  months = {'1':'Jan', '2':'Feb','3':'March','4':'April','5':'May','6':'Jun','7':'Jul','8':'Aug','9':'Sept','10':'Oct','11':'Nov','12':'Dec'};
      // let monthsData = [1,2,3,4,5,6,7,8,9,10,11,12]
      // monthsData.forEach((item) => {

      //   let respobj = res.data.find(x => x._id.date === item && x._id.hsm == true)
      // })
      console.log('res.datares.data',res.data)
      let finalArry = []
      res && res.data && res.data.length>0 && res.data.forEach((value)=>{
      console.log('res.datares.data',value)

          if(value.month == 1){
            finalArry.push({
              month:'Jan',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 2){
            finalArry.push({
              month:'Feb',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 3){
            finalArry.push({
              month:'March',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 4){
            finalArry.push({
              month:'April',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 5){
            finalArry.push({
              month:'May',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 6){
            finalArry.push({
              month:'Jun',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 7){
            finalArry.push({
              month:'Jul',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 8){
            finalArry.push({
              month:'Aug',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 9){
            finalArry.push({
              month:'Sept',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 10){
            finalArry.push({
              month:'Oct',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 11){
            finalArry.push({
              month:'Nov',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }else if(value.month == 12){
            finalArry.push({
              month:'Dec',
              positive: (value.positiveSentiment*100).toFixed(),
              negetive: (value.negativeSentiment*100).toFixed(),
              neutral: (value.neutralSentiment*100).toFixed(),
            })
          }
      })
      console.log('res.datares.data--',allData)
        this.setState({
        distribution:finalArry
      })
    })
    // debugger
    let allData =  this.state.distribution
    let positive =    allData && allData.length > 0 && allData.map(a => a.positive);
    let negetive =    allData && allData.length > 0 && allData.map(a => a.negetive);
    let neutral =    allData && allData.length > 0 && allData.map(a => a.neutral);
    let month =    allData && allData.length > 0 && allData.map(a => a.month);
    
    await  this.setState({
      positive,
      negetive,
      neutral,
      month
    })
    await this.getFourBar()
  }
  async sortGraph(value) {
    this.setState({
      monthValue:value
    })
    this.getDistribution(value,this.state.department)
  }

  async sortGraphByDepartment(value) {
    this.setState({
      department:value
    })
    this.getDistribution(this.state.monthValue,value)
  }
  async callNotificationServiceAgent (startDate,endDate) {
    await clientService.getPromotion(startDate,endDate).
    then((res)=>{
      // debugger
      if(res){
        this.setState({
          allNotificationAgentData:res.data.response
      })
      }
        
    })

    // let allData = this.state.allNotificationAgentData;
    // let distarctors = allData && allData.length > 0 && allData[0].distarctors ? allData[0].distarctors : 0 
    // let passives = allData && allData.length > 0 && allData[0].passives ? allData[0].passives : 0
    // let promotors = allData && allData.length > 0 && allData[0].promotors ? allData[0].promotors : 0
    // let notificationAgentData = await  allData && allData.length > 0 && allData.map(a => a.name);
    // let notificationAgentXAxix = await allData && allData.length > 0 && allData.map(a => a.averageQuantity);
    // await this.setState({distarctors,passives,promotors,allData})
    // await this.setState({allData})
    await this.getDataSingleBarHorizontal()
}
  
getDataSingleBarHorizontal() {
  console.log('this.state.notificationAgentXAxix',this.state.allNotificationAgentData)
  if (this.state.allNotificationAgentData.length > 0) {
    const {allNotificationAgentData} = this.state
    let passives = allNotificationAgentData && allNotificationAgentData.length > 0 && allNotificationAgentData[0].passives ? allNotificationAgentData[0].passives : 0
    let distarctors = allNotificationAgentData && allNotificationAgentData.length > 0 && allNotificationAgentData[0].distarctors ? allNotificationAgentData[0].distarctors : 0
    let promotors = allNotificationAgentData && allNotificationAgentData.length > 0 && allNotificationAgentData[0].promotors ? allNotificationAgentData[0].promotors : 0
      let data = {
          bindto: '#graph3',
          x: 'x',
          columns: [
              // ['x', ...this.state.notificationAgentData],
              // ['Average Rating', ...this.state.notificationAgentXAxix],
              ['x', "Passives","Distarctors","Promotors"],
              ['Average Rating', passives,distarctors,promotors]
              
          ],
          type: 'bar',
          color: function(inColor, data) {
            var colors = ['#16B40B', '#f4c430','#ACBGTY'];
            if(data.index !== undefined) {
                return colors[data.index];
            }

            return inColor;
        }


      }

      return data
  } else {
      let data = {
          bindto: '#graph3',
          x: 'x',
          columns: [
              ['x',null],
              ['Average Rating',null],
          ],
          type: 'bar',
          color: function(inColor, data) {
            var colors = ['#16B40B', '#f4c430','#ACBGTY'];
            if(data.index !== undefined) {
                return colors[data.index];
            }

            return inColor;
        }

      }
      return data
  }


}
handleSubmitSingleBarHorizontal = async event => {
    event.preventDefault();
 
    this.callNotificationServiceAgent(this.state.filterStartDateSingleBarHorizontal, this.state.filterEndDateSingleBarHorizontal)
    this.getDataSingleBarHorizontal()
}
handleChangeBarHorizontal(type, date) {
    console.log('datedate',date)
    // debugger
    let changeDate = new Date(date).toLocaleDateString('en-US')
    // console.log('datedate---',changeDate)

    // this.setState({graphStatus:false})
    this.setState({
        [type]: date
    });
    if (type === 'startDateSingleBarHorizontal') {
        this.setState({ filterStartDateSingleBarHorizontal: changeDate })
    } else {
        this.setState({ filterEndDateSingleBarHorizontal: changeDate })
    }

}

getWordCount (startDate,endDate){
  clientService.getWordCount(startDate,endDate).then(res => {
    // debugger
      console.log('res.datares.data',res.data)
      let arrayObj = res.data.map(item => {
          return {
              text: item._id,
              value: item.count
          };
        });
        
     this.setState({
      wordData:arrayObj
     })
      // this.setState({ dashboardData: res.data.data })
  });
}
getHospitalList(){
  clientService.getHospitalList().then((res) => {
    let HospitalArray = []
    res.data.result.map((item) => HospitalArray.push({name:item.name_en,id:item.id}))
    this.setState({
      departmentArray: HospitalArray
    })
  })
}
 
handleChangeWord(type, date) {
  let changeDate = new Date(date).toLocaleDateString('en-US')
  // this.setState({graphStatus:true})
  this.setState({
      [type]: date
  });
  if (type === 'startDateWord') {
      this.setState({ filterStartDateWord: changeDate })
  } else {
      this.setState({ filterEndDateWord: changeDate })
  }
}
handleSubmitWord = async event => {
  event.preventDefault();

  this.getWordCount(this.state.filterStartDateWord, this.state.filterEndDateWord)
}

getFourBar(){
  
  if(this.state.neutral.length > 0){
    let data = {
      bindto: '#graph6',
      x:'x',
      columns: [
        ['x', ...this.state.month],
        ['Positive', ...this.state.positive],
        ['Negative', ...this.state.negetive],
        ['Neutral', ...this.state.neutral]
    ],
      type: 'bar',
      groups: [
        ['Positive', 'Negative','Neutral']
    ]
  
  
  
  }
  
  return data
  }else{
    let data = {
      bindto: '#graph6',
      x:'x',
      columns: [
        ['x', null],
        ['Positive', null],
        ['Negative', null],
        ['Neutral', null]
    ],
      type: 'bar',
      groups: [
        ['Positive', 'Negative','Neutral']
    ]
  
  
  
  }
  
  return data
  }
  
}

getGauge(){

  if (this.state.allNotificationAgentData.length > 0) {
    const {allNotificationAgentData} = this.state
    let passives = allNotificationAgentData && allNotificationAgentData.length > 0 && allNotificationAgentData[0].passives ? allNotificationAgentData[0].passives : 0
    let distarctors = allNotificationAgentData && allNotificationAgentData.length > 0 && allNotificationAgentData[0].distarctors ? allNotificationAgentData[0].distarctors : 0
    let promotors = allNotificationAgentData && allNotificationAgentData.length > 0 && allNotificationAgentData[0].promotors ? allNotificationAgentData[0].promotors : 0
  
    let gValue = parseFloat(promotors) - parseFloat(distarctors) 
    let data = {
      columns: [
          ['data', gValue]
      ],
      type: 'gauge',
      onclick: function (d, i) { console.log("onclick", d, i); },
      onmouseover: function (d, i) { console.log("onmouseover", d, i); },
      onmouseout: function (d, i) { console.log("onmouseout", d, i); }
  }
  
  return data
  }else{
    let data = {
      columns: [
          ['data', null]
      ],
      type: 'gauge',
      onclick: function (d, i) { console.log("onclick", d, i); },
      onmouseover: function (d, i) { console.log("onmouseover", d, i); },
      onmouseout: function (d, i) { console.log("onmouseout", d, i); }
  }
  
  return data
  }
 

}
  render() {
    // let departmentArray = [
    //   "Emergency",
    //   "Outpatient clinics",
    //   "Admission",
    //   "Doctor",
    //   "Nursing",
    //   "Food Services",
    //   "Diagnostics",
    //   "Housekeeping",
    //   "Pharmacy"
    // ];

  

    return (
      <div>
        {console.log("Sentiment Analysis",this.getFourBar())}
        <h4 >Sentiment Analysis</h4>
              {/* <div className="box-al mar-40">
                            <form onSubmit={this.handleSubmitSingleBarHorizontal}>
                                <div className="row">
                                    <div className="col col-6 col-sm-4 col-lg-5">
                                        <label className="form-label">From Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.startDateSingleBarHorizontal}
                                            onChange={(e) => this.handleChangeBarHorizontal('startDateSingleBarHorizontal', e)}
                                            maxDate={this.state.endDateSingleBarHorizontal}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <div className="col col-6 col-sm-4 col-lg-5">
                                        <label className="form-label">To Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.endDateSingleBarHorizontal}
                                            onChange={(e) => this.handleChangeBarHorizontal('endDateSingleBarHorizontal', e)}
                                            maxDate={new Date()}
                                            minDate={this.state.startDateSingleBarHorizontal}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-4 col-lg-2"><br />
                                        <Button color="primary   sub1" type="submit">Submit</Button>
                                    </div>
                                </div>
                            </form>
                        </div> */}
                         <div className="box-3rd card card101">
                        {/* <div className="card-body p-3 text-center">
                        <C3Chart
                            gauge={ {
                              //        label: {
                              //            format: function(value, ratio) {
                              //                return value;
                              //            },
                              //            show: false // to turn off the min/max labels.
                              //        },
                                  min: -100, // 0 is default, //can handle negative min e.g. vacuum / voltage / current flow / rate of change
                              //    max: 100, // 100 is default
                              //    units: ' %',
                              //    width: 39 // for adjusting arc thickness
                                  }}
                            data ={this.getGauge()}
                            size = {{
                              height: 180
                            }}
                            color= {{
                              pattern: ['#FF0000', '#F97600', '#F6C600', '#60B044'], // the three color levels for the percentage values.
                              threshold: {
                      //            unit: 'value', // percentage is default
                      //            max: 200, // 100 is default
                                  // values: [30, 60, 90, 100]
                              }
                          }}
                            // axis= {{
                            //     rotated: true,
                            //     x: {
                            //         type: 'category'
                            //     }
                            // }}
                            // tooltip= {{
                            //     grouped: false
                            // }}
                            // legend={ {
                            //     show: false
                            // }}
                         />
                        </div> */}
                        {/* <div className="card-body p-3 text-center">
                        <C3Chart
                            size= {{
                                height: 500
                            }}
                            bar= {{
                                width: 50
                            }}
                            padding={ {
                                left: 100
                            }}
                            color= {{
                                pattern: ['#16B40B', '#f4c430','#ACBGTY']
                            }}
                            data ={this.getDataSingleBarHorizontal()}
                            axis= {{
                                rotated: true,
                                x: {
                                    type: 'category'
                                }
                            }}
                            tooltip= {{
                                grouped: false
                            }}
                            legend={ {
                                show: false
                            }}
                         />
                              
                                  
                                </div> */}

               {/* <div className="newgrapghheight">
                   <div>
                     
                   <div className="row"> 
                            <form onSubmit={this.handleSubmitWord}>
                                <div className="row">
                                    <div className="col col-sm-5 ">
                                        <label className="form-label">From Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.startDateWord}
                                            onChange={(e) => this.handleChangeWord('startDateWord', e)}
                                            maxDate={this.state.endDateWord}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                            maxDate={new Date()}
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-5 ">
                                        <label className="form-label">To Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.endDateWord}
                                            onChange={(e) => this.handleChangeWord('endDateWord', e)}
                                            maxDate={new Date()}
                                            minDate={this.state.startDateWord}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>
                                    <div className="col col-6 col-sm-2 "><br />
                                        <Button color="primary   sub2" type="submit">Submit</Button>
                                    </div>
                                </div>

                            </form>
                      </div>  
                   <div className="card card101"> 
                        <div className="row">
                        <div class=" pull-left col col-12 col-sm-12 col-lg-7 "><h3 class="card-title">Title1</h3></div>
                        </div>
                      <div className="card-body p-3 text-center">
                      
                        <ReactWordcloud 
                    
                        options={options} 
                        words={this.state.wordData} 
                        />
                         <ReactWordcloud  words={words} /> 
                      </div>
                  </div>
                  </div>
                </div> */}
                <div>
                <div className="card-body p-3 text-center">
                <div style={{ display: 'inline-block', marginRight: '10px' }}>
                                    <div className="">
                                        <select 
                                        style={{
                                          padding: '8px',
                                          borderRadius: '5px',
                                          border: '1px solid #ccc',
                                          fontSize: '16px',
                                          backgroundColor: '#fff',
                                      }}
                                        className=""
                                            onChange={(e) => this.sortGraph(e.target.value)} value={this.state.monthValue}>
                                             <option value="">
                                               Please Select Year
                                            </option> 
                                            <option value="2021">
                                            2021
                                            </option>
                                            <option value="2022">
                                            2022
                                            </option>
                                            <option value="2023">
                                            2023
                                            </option>
                                            <option value="2024">
                                            2024
                                            </option> 
                                            <option value="2025">
                                            2025
                                            </option>
                                            <option value="2026">
                                            2026
                                            </option>
                                            <option value="2027">
                                            2027
                                            </option>
                                            <option value="2028">
                                            2028
                                            </option>
                                            <option value="2029">
                                            2029
                                            </option>
                                            <option value="2030">
                                            2030
                                            </option>
                                            <option value="2031">
                                            2031
                                            </option>
                                            <option value="2032">
                                            2032
                                            </option>
                                            

                                        </select>
                                    </div>
                                </div>

                                <div style={{ display: 'inline-block' }}>
                                    <div className="">
                                        <select 

                                        style={{
                                          padding: '8px',
                                          borderRadius: '5px',
                                          border: '1px solid #ccc',
                                          fontSize: '16px',
                                          backgroundColor: '#fff',
                                      }}
                                        className=""
                                            onChange={(e) => this.sortGraphByDepartment(e.target.value)} value={this.state.department}>
                                             <option value="">
                                               Please Select Hospital
                                            </option> 
                                            {this.state.departmentArray.map((val,i)=> (
                                              <option key={i} value={val.id}>
                                                {val.name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                        <C3Chart
                            // size= {{
                            //     height: 500
                            // }}
                            bar= {{
                                width: 100
                            }}
                            // padding={ {
                            //     left: 100
                            // }}
                            // color= {{
                            //     pattern: ['#16B40B', '#f4c430','#ACBGTY']
                            // }}
                            data ={this.getFourBar()}
                          //   grid = {{
                          //     y: {
                          //         lines: [{value:0}]
                          //     }
                          // }}
                            axis= {{
                                // rotated: true,
                                x: {
                                    type: 'category'
                                },
                                y: {
                                  max: 100,
                                  min: 0,
                                  // Range includes padding, set 0 if no padding needed
                                  padding: {top:0, bottom:0}
                              }
                            }}
                            // tooltip= {{
                            //     grouped: false
                            // }}
                            // legend={ {
                            //     show: false
                            // }}
                         />
                              
                                  
                                </div>

                </div>
                </div>
       </div>
        )
  }

}


const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout())
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedBackAnalytics));
