// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button
} from "tabler-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import authActions from "../../redux/auth/action";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import { validate } from '../../validate';
import clientService from "../../services/client";
import Buttonloader from '../../common/Loader/Buttonloader'
import { changeLanguage } from '../../../src/language/index';

const validationRules = [
  // existing validation rules...
  {
    field: 'dnValueTelugu',
    validations: ['required'],
    name: 'Dormant Notification Message (Telugu)'
  },
  {
    field: 'ecmValueTelugu',
    validations: ['required'],
    name: 'End Chat Message (Telugu)'
  },
  {
    field: 'qdmValueTelugu',
    validations: ['required'],
    name: 'Queue Drop Message (Telugu)'
  }
];

class EndSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        dValue: 0,
        dceValue: 0,
        dnValue: '',
        dnValueTelugu: '', // Added Telugu field
        ecmValue: '',
        ecmValueTelugu: '', // Added Telugu field
        mqwValue: 0,
        qdmValue: '',
        qdmValueTelugu: '', // Added Telugu field
        automaticEndChat: '',
      },
      message: {
        style: 'success',
        text: ''
      },
      isLoading: false,
      errors: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
  }

  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  handleSubmit = async event => {
    event.preventDefault();
    if (this.validate()) {
      console.log('working')
      this.setLoadingToggle(true);

      const data = {
        automaticEndChat: this.state.fields.automaticEndChat,
        waitingEndTime: this.state.fields.mqwValue,
        waitingEndText: this.state.fields.qdmValue,
        waitingEndText_kn: this.state.fields.qdmValueTelugu,

        endChatObj: {
          dormantNotificationTime: Number(this.state.fields.dValue),
          endChatTime: Number(this.state.fields.dceValue),
          dormantNotificationText: this.state.fields.dnValue,
          dormantNotificationText_kn: this.state.fields.dnValueTelugu,
          endChatText: this.state.fields.ecmValue,
          endChatText_kn: this.state.fields.ecmValueTelugu,

        },
       
      }

      clientService.settings(data)
        .then(response => {
          this.props.user.settingData.automaticEndChat = this.state.fields.automaticEndChat
          this.props.user.settingData.waitingEndTime = this.state.fields.mqwValue
          this.props.user.settingData.waitingEndText = this.state.fields.qdmValue
          this.props.user.settingData.waitingEndText_kn = this.state.fields.qdmValueTelugu

          this.props.user.settingData.endChatObj = {
            dormantNotificationTime: this.state.fields.dValue,

            endChatTime: this.state.fields.dceValue,
            dormantNotificationText: this.state.fields.dnValue,
            dormantNotificationText_kn: this.state.fields.dnValueTelugu,

            endChatText: this.state.fields.ecmValue,
            endChatText_kn: this.state.fields.ecmValueTelugu

          }
         
          console.log(this.props.user)
          this.props.updateUserData(this.props.user);
          toast.success("Setting Changed successfully.")
          setTimeout(() => {
            this.props.history.push("/admin/all-chat/1");
          }, 3000)
        })
        .catch(error => {
          this.setLoadingToggle(false);
          console.log(error)
          setTimeout(() => {
            this.handleNotification();
          }, 3000)
        })
    }
    else {
      console.log('eeeeeeee');
    }
  }
  componentDidMount() {
    document.title = "Voiceoc | End Chat Settings"
    if (this.props.user != undefined) {
      const { fields } = this.state;
      console.log("text----", fields)

      fields['dValue'] = this.props.user.settingData.endChatObj.dormantNotificationTime;
      fields['dceValue'] = this.props.user.settingData.endChatObj.endChatTime
      fields['dnValue'] = this.props.user.settingData.endChatObj.dormantNotificationText
      fields['ecmValue'] = this.props.user.settingData.endChatObj.endChatText
      fields['automaticEndChat'] = this.props.user.settingData.automaticEndChat
      fields['mqwValue'] = this.props.user.settingData.waitingEndTime
      fields['qdmValue'] = this.props.user.settingData.waitingEndText
      fields['dnValueTelugu'] = this.props.user.settingData.endChatObj.dormantNotificationText_kn; // Set Telugu field
      fields['ecmValueTelugu'] = this.props.user.settingData.endChatObj.endChatText_kn; // Set Telugu field
      fields['qdmValueTelugu'] = this.props.user.settingData.waitingEndText_kn; // Set Telugu field
      this.setState({ fields });
      console.log(this.props)
    }
  }

  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  handleChangecheckbox(e) {
    let isChecked = e.target.checked ? true : false;
    const { fields } = this.state;
    fields['automaticEndChat'] = isChecked
    this.setState({
      fields
    });
    console.log(this.state.fields)
  }

  render() {
    const { message } = this.state;

    return (
      <SiteWrapperSuperAdmin>
        <Grid.Col lg={5} className="mx-auto ">
          <Card>
            <Card.Header>
              <Card.Title>{changeLanguage(this.props.siteLanguage, 'endchat_setting')}</Card.Title>
            </Card.Header>
            <Card.Body>
              {!!message.text ?
                <div className={`alert alert-${message.style}`} role="alert">
                  {message.text}
                </div>
                : null
              }
              <Form onSubmit={this.handleSubmit}>
                <Form.Group>
                  <Form.Checkbox
                    label={changeLanguage(this.props.siteLanguage, 'auto_chat_end')}
                    checked={this.state.fields.automaticEndChat}
                    onChange={(e) => this.handleChangecheckbox(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{changeLanguage(this.props.siteLanguage, 'dormant_noti_time')}</Form.Label>
                  <Form.Input
                    type="number"
                    min="0"
                    value={this.state.fields.dValue}
                    onChange={(e) => this.handleChange(e, 'dValue')}
                  />
                  {this.state.errors.dValue && <div className="invalid-feedback">{this.state.errors.dValue}</div>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{changeLanguage(this.props.siteLanguage, 'dormant_end')}</Form.Label>
                  <Form.Input
                    type="number"
                    min="0"
                    value={this.state.fields.dceValue}
                    onChange={(e) => this.handleChange(e, 'dceValue')}
                  />
                  {this.state.errors.dceValue && <div className="invalid-feedback">{this.state.errors.dceValue}</div>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{changeLanguage(this.props.siteLanguage, 'dormant_noti_msg')}</Form.Label>
                  <Form.Textarea
                    value={this.state.fields.dnValue}
                    onChange={(e) => this.handleChange(e, 'dnValue')}
                  />
                  {this.state.errors.dnValue && <div className="invalid-feedback">{this.state.errors.dnValue}</div>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{changeLanguage(this.props.siteLanguage, 'dormant_noti_msg_te')}</Form.Label>
                  <Form.Textarea
                    value={this.state.fields.dnValueTelugu}
                    onChange={(e) => this.handleChange(e, 'dnValueTelugu')}
                  />
                  {this.state.errors.dnValueTelugu && <div className="invalid-feedback">{this.state.errors.dnValueTelugu}</div>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{changeLanguage(this.props.siteLanguage, 'endchat_msg')}</Form.Label>
                  <Form.Textarea
                    value={this.state.fields.ecmValue}
                    onChange={(e) => this.handleChange(e, 'ecmValue')}
                  />
                  {this.state.errors.ecmValue && <div className="invalid-feedback">{this.state.errors.ecmValue}</div>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{changeLanguage(this.props.siteLanguage, 'endchat_msg_te')}</Form.Label>
                  <Form.Textarea
                    value={this.state.fields.ecmValueTelugu}
                    onChange={(e) => this.handleChange(e, 'ecmValueTelugu')}
                  />
                  {this.state.errors.ecmValueTelugu && <div className="invalid-feedback">{this.state.errors.ecmValueTelugu}</div>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{changeLanguage(this.props.siteLanguage, 'maximum_queue_wait')}</Form.Label>
                  <Form.Input
                    type="number"
                    min="0"
                    value={this.state.fields.mqwValue}
                    onChange={(e) => this.handleChange(e, 'mqwValue')}
                  />
                  {this.state.errors.mqwValue && <div className="invalid-feedback">{this.state.errors.mqwValue}</div>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{changeLanguage(this.props.siteLanguage, 'drop_msg')}</Form.Label>
                  <Form.Textarea
                    value={this.state.fields.qdmValue}
                    onChange={(e) => this.handleChange(e, 'qdmValue')}
                  />
                  {this.state.errors.qdmValue && <div className="invalid-feedback">{this.state.errors.qdmValue}</div>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{changeLanguage(this.props.siteLanguage, 'drop_msg_te')}</Form.Label>
                  <Form.Textarea
                    value={this.state.fields.qdmValueTelugu}
                    onChange={(e) => this.handleChange(e, 'qdmValueTelugu')}
                  />
                  {this.state.errors.qdmValueTelugu && <div className="invalid-feedback">{this.state.errors.qdmValueTelugu}</div>}
                </Form.Group>
                {!this.state.isLoading ?
                  <Button color="primary" type="submit">{changeLanguage(this.props.siteLanguage, 'change')}</Button>
                  :
                  <Buttonloader loading={this.state.isLoading} />
                }
              </Form>
            </Card.Body>
          </Card>
        </Grid.Col>
        <ToastContainer autoClose={5000} position={'bottom-right'} />
      </SiteWrapperSuperAdmin>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    siteLanguage: state.auth.siteLanguage // Keeping existing siteLanguage name
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateUserData: (payload) => {
      dispatch(authActions.updateUserData(payload))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EndSettings));
